<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-card class="mt-24 mb-15 pb-0" style="background-color: #fff; margin-top: 0px;">
			
            <a-row :gutter="24" class="mb-0 pb-0">

                <!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-0 pb-0">
                    <a-select default-value="Tanzania, United Republic of" style="border: none !important; margin-top: 4px;" class="text-right" @change="handleChange">
                        
                        <a-select-option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                        </a-select-option>
                        <a-select-option value="Kenya">
                            Kenya
                        </a-select-option>
                    </a-select>
                </a-col> -->

                <a-col :span="24" :md="8" :lg="8" :xl="8" class="mb-0 pb-0">
                    
                    <a-form-item style="width: 100% !important;" class="mb-0 pb-0">
                        <a-input v-model="pagination.search" placeholder=" Search ..." class="w-full pb-0 mb-0" :bordered="false" style="width: 100%;">
                            <template #prefix>
                                <a-icon type="search" theme="outlined"> </a-icon>
                            </template>
                            <template #suffix v-if="pagination.search !== ''">
                                <a-button type="link" @click="cancelSearch" style="border: none;">
                                    <a-icon type="close" theme="outlined" />
                                </a-button>
                            </template>
                        </a-input>
                    </a-form-item>
                    
                </a-col>

                <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-0 pb-0">
                        <a-button
                            id="btnSearch"
                            :type="pagination.search.length > 0 ? 'primary' : ''"
                            @click="submitSearch"
                            :class="'mt-5'"
                            :disabled="pagination.search === ''">
                            <a-icon type="search" theme="outlined" />
                            Search
                        </a-button>
                    <!-- <a-button
                        id="btnSearch"
                        :disabled="true">
                        <a-icon type="filter" theme="outlined" />
                        Filter
                    </a-button> -->
                </a-col>

                <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                    <!-- <a-button
                        type="primary"
                        @click="toCreateNewClass"
                        class="">
                        <a-icon type="plus" theme="outlined" />
                        Create New Class
                    </a-button> -->
                </a-col>

            </a-row>

        </a-card>

        <!-- <a-row :gutter="24" class="px-20">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option.uuid)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
            </a-col>
        </a-row> -->

        <!-- <a-row :gutter="24" class="px-20">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
                <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
            </a-col>
        </a-row> -->

        <div v-if="!loadingData" class="mb-20 mt-20 pt-20">

            <a-row :gutter="24" class="px-20">

                <a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24 pb-10" v-for="clas in classes" :key="clas.uuid">
                    <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15">
                        <a class="text-dark p-0 m-0" style="text-decoration: none !important;" :href="'/my-classes/view/' + clas.uuid">
                            <a-row :gutter="24">
                                <a-col :span="24">
                                    <div class="icon">
                                        <img id="classImg" width="100%" height="200px" :src="clas.thumbnail != null ? clas.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24" class="px-20">
                                <a-col :span="20">
                                    <h6 class="mt-10 pt-4 pl-2">{{ clas.name.length > 20 ? `${clas.name.substring(0, 20)}...` : clas.name }}</h6>
                                </a-col>

                                <a-col :span="4">
                                    <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                    </svg>
                                </a-col>

                                
                            </a-row>

                            <a-row :gutter="24" class="px-20">
                                <a-col :span="24" :md="16" :lg="16" :xl="16">
                                    <!-- <a-avatar :src="clas.creator != null && clas.creator.profilePicUrl ? clas.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                    
                                    <a-row :gutter="24" class="">
                                        <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            {{ clas.creator != null ? clas.creator.firstName.length + clas.creator.lastName.length > 0 ? `${formatName(clas.creator.firstName)} .${clas.creator.lastName[0].toUpperCase()}` : `${formatName(clas.creator.firstName)} ${formatName(clas.creator.lastName)}` : 'Unknown'}}, Teacher
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                            <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                            </svg>
                                            <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                            </svg>
                                        </a-col>
                                    </a-row>

                                </a-col>
                                <a-col :span="24" :md="8" :lg="8" :xl="8" class="text-right">
                                    <h6 class="text-silabu pt-10">{{ clas.price != null && clas.price != 0 ? `${clas.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row>

                            <!-- <a-row :gutter="24" class="mt-10 px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                    <span><strong>{{ clas.slots + 1 - clas.membersCount }}</strong></span> <small>Slots remain </small>
                                </a-col>

                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pr-5">{{ clas.price != null && clas.price != 0 ? `${clas.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row> -->
                        </a>
                    </a-card>
                </a-col>

            </a-row>

        </div>

        <div v-if="loadingData">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <a-spin class="text-primary" size="large"/>

                </a-col>

            </a-row>
        </div>

        <a-modal v-model="filter.showModal" :footer="null">
            
            <a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row> -->

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutters="24" class="mt-20 pt-20">
                <a-col :span="24" class="text-right">
                    <a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
                        Clear all filters
                    </a-button>
                    &nbsp;&nbsp;
                    <a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
                        Appy filters
                    </a-button>
                </a-col>
            </a-row>
            
        </a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				classes: [],
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'all',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },


                filterItems: [
                    { uuid: 'all', name: 'All Classes ', icon: ' 🎓'},
                    { uuid: 'favorites', name: 'Wishlist', icon: ' ❤️' },
                    { uuid: 'archived', name: 'Archived ', icon: ' 🗃️'}
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getClasses();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getClasses()
                },
            },
            deep: true,
        },
		created() {
			this.getClasses()
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getClasses();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getClasses();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getClasses();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getClasses();
            },

			toCreateNewClass() {
				this.$router.push('/my-classes/create-new-class')
			},


            onSelectFilterItem(item) {
                this.pagination.filter = item;

                this.getClasses();
            },

			async getClasses() {

                console.log(localStorage.getItem("user_token"))

				this.loadingData = true;

                const { currentPage, perPage, search, filter } = this.pagination;

                let url = '';

                if(filter == 'all') {
                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/classes/mine?search=${search}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes/mine?page=${currentPage}&size=${perPage}`;
                    }
                }

                if(filter == 'favorites') {
                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/classes/favorites?search=${search}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes/favorites?page=${currentPage}&size=${perPage}`;
                    }
                }


                if(filter == 'archived') {
                    if(search != null && search.length > 0) {
                        url = `${this.$BACKEND_URL}/classes/archived?search=${search}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes/archived?page=${currentPage}&size=${perPage}`;
                    }
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>