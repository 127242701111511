<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutters="[24, 24]">
            <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-20 pt-20 mb-24 pb-10">
                <a-tabs v-model="activeTab" @change="onTabChange($event)" class="">

                    <a-tab-pane key="Classes" tab="Classes">
                        <my-classes/>
                    </a-tab-pane>


                    <a-tab-pane key="Quizzes" tab="Quizzes">
                        <my-quizzes/>
                    </a-tab-pane>


                    <a-tab-pane key="Notes" tab="Notes">
                        <my-notes/>
                    </a-tab-pane>
                    
                </a-tabs>
            </a-col>
		</a-row>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner';
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
	import MyClasses from './MyClasses';
	import MyNotes from './MyNotes';
	import MyQuizzes from './MyQuizzes' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
            MyClasses,
            MyNotes,
            MyQuizzes
		},

		data() {
			return {

				loadingData: false,

                activeTab: null,

      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getClasses();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getClasses()
                },
            },
            deep: true,
        },
		created() {
            this.getActiveTab();
		},
		methods: {

            async getActiveTab() {

                const activeTab = await localStorage.getItem('classQuizANoteActiveTab')

                if(activeTab != null) {
                    this.activeTab = activeTab;
                }else {
                    this.activeTab = "Classes";
                }
            },

            async onTabChange(event) {
				await localStorage.setItem('classQuizANoteActiveTab', event)
			},

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>