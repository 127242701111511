<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-card class="mt-24 mb-24 pb-0" style="background-color: #fff; margin-top: 0px;">
			
            <a-row :gutter="24" class="mb-0 pb-0">

                <!-- <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-0 pb-0">
                    <a-select default-value="Tanzania, United Republic of" style="border: none !important; margin-top: 4px;" class="text-right" @change="handleChange">
                        
                        <a-select-option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                        </a-select-option>
                        <a-select-option value="Kenya">
                            Kenya
                        </a-select-option>
                    </a-select>
                </a-col> -->

                <a-col :span="24" :md="8" :lg="8" :xl="8" class="mb-0 pb-0">
                    
                    <a-form-item style="width: 100% !important;" class="mb-0 pb-0">
                        <a-input v-model="pagination.search" placeholder=" Search ..." class="w-full pb-0 mb-0" :bordered="false" style="width: 100%;">
                            <template #prefix>
                                <a-icon type="search" theme="outlined"> </a-icon>
                            </template>
                            <template #suffix v-if="pagination.search !== ''">
                                <a-button type="link" @click="cancelSearch" style="border: none;">
                                    <a-icon type="close" theme="outlined" />
                                </a-button>
                            </template>
                        </a-input>
                    </a-form-item>
                    
                </a-col>

                <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-0 pb-0">
                        <a-button
                            id="btnSearch"
                            :type="pagination.search.length > 0 ? 'primary' : ''"
                            @click="submitSearch"
                            :class="'mt-5'"
                            :disabled="pagination.search === ''">
                            <a-icon type="search" theme="outlined" />
                            Search
                        </a-button>
                    <!-- <a-button
                        id="btnSearch"
                        :disabled="true">
                        <a-icon type="filter" theme="outlined" />
                        Filter
                    </a-button> -->
                </a-col>

                <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                    <a-button
                        v-if="userDetails.role != null && userDetails.role.code == 'teacher'"
                        type="primary"
                        @click="toCreateNewQuiz"
                        class="">
                        <a-icon type="plus" theme="outlined" />
                        Create New Quiz
                    </a-button>
                </a-col>

            </a-row>

        </a-card>

        <!-- <a-row :gutter="24" class="px-20">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
                <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
            </a-col>
        </a-row> -->

        <div v-if="!loadingData" class="mb-20 mt-20 pt-20">

            <a-row :gutter="24" class="px-20 mt-20 pt-20">

                <a-col :span="24"  :md="6" :lg="6" :xl="6" class="mb-24 pb-10" v-for="qui in quizzes" :key="qui.uuid">
                    <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15">
                        <a class="text-dark p-0 m-0" style="text-decoration: none !important;" target="_blank" :href="`/quizzes/view/${qui.uuid}/start`">
                            <a-row :gutter="24">
                                <a-col :span="24">
                                    <div class="icon">
                                        <img id="classImg" width="100%" height="200px" :src="qui.thumbnail != null ? qui.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24" class="px-20">
                                <a-col :span="20">
                                    <h6 class="mt-10 pt-4 pl-2">{{ qui.title.length > 20 ? `${qui.title.substring(0, 20)}...` : qui.title }}</h6>
                                </a-col>

                                <a-col :span="4">
                                    <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                    </svg>
                                </a-col>

                                
                            </a-row>

                            <a-row :gutter="24" class="px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <!-- <a-avatar :src="qui.creator != null && qui.creator.profilePicUrl ? qui.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                    
                                    <a-row :gutter="24" class="">
                                        <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            Duration: {{ qui.duration }} minutes
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                            Time: {{ $Moment(qui.updatedAt).format("DD MMM hh:mm a") }}
                                        </a-col>
                                    </a-row>

                                </a-col>
                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pt-10">{{ qui.price != null && qui.price != 0 ? `${qui.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row>

                            <!-- <a-row :gutter="24" class="mt-10 px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                    <span><strong>{{ qui.slots + 1 - qui.membersCount }}</strong></span> <small>Slots remain </small>
                                </a-col>

                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pr-5">{{ qui.price != null && qui.price != 0 ? `${qui.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row> -->
                        </a>
                    </a-card>
                </a-col>

            </a-row>

        </div>

        <div v-if="loadingData">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <a-spin class="text-primary" size="large"/>

                </a-col>

            </a-row>
        </div>

        <a-modal v-model="filter.showModal" :footer="null">
            
            <a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row> -->

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutters="24" class="mt-20 pt-20">
                <a-col :span="24" class="text-right">
                    <a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
                        Clear all filters
                    </a-button>
                    &nbsp;&nbsp;
                    <a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
                        Appy filters
                    </a-button>
                </a-col>
            </a-row>
            
        </a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				quizzes: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                userDetails: {},

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getNotes();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getNotes()
                },
            },
            deep: true,
        },
		created() {
            this.initializeFilter();
			this.getNotes()
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getNotes();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getNotes();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getNotes();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getNotes();
            },

			toCreateNewQuiz() {
				this.$router.push('/my-classes/create-new-quiz')
			},

            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

			async getNotes() {

                const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {

                    let userDetails = JSON.parse(userInfo);

                    this.userDetails = userDetails;

                    this.loadingData = true;

                    const { currentPage, perPage, search } = this.pagination;

                    let url = '';
                    if(search != null && search.length > 0) {
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/quizzes?creator=${userDetails.uuid}&search=${search}&filter=true&price=0>=<=${this.filter.price}&page=${currentPage}&size=${perPage}`;
                        }else{
                            url = `${this.$BACKEND_URL}/quizzes?creator=${userDetails.uuid}&search=${search}&page=${currentPage}&size=${perPage}`;
                        }
                    }else{
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/quizzes?creator=${userDetails.uuid}&filter=true&price=0><${this.filter.price}&page=${currentPage}&size=${perPage}`;
                        }else{
                            url = `${this.$BACKEND_URL}/quizzes?creator=${userDetails.uuid}&page=${currentPage}&size=${perPage}`;
                        }
                    }

                    

                    this.$AXIOS.get(url).then(async(response) => {
                        if (response.status == 200) {
                            this.quizzes = response.data.data;
                            this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                            this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                            this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                            this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                        }
                        
                        this.loadingData = false;
                        
                    }).catch(async(error) => {
                        
                        this.loadingData = false;

                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    });
                }
            }

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>